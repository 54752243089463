<template>
  <section
    class="section breadcrumb-area overlay-dark d-flex align-items-center"
    :style="{ background: `rgba(0, 0, 0, 0) url(${ this.background }) no-repeat fixed center center / cover`} "
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Breamcrumb Content -->
          <div class="breadcrumb-content text-center">
            <h2 class="text-white text-uppercase mb-3">{{ titulo ? titulo : "Blog" }}</h2>
            <h3 class="text-white-50 mb-3">
              {{ descripcion ? descripcion : "Posts y nuevo contenido relacionado con aspectos legales, políticos, electorales y tecnológicos"}}
            </h3>
            <!-- <ol class="breadcrumb d-flex justify-content-center">
                    <li class="breadcrumb-item"><a class="text-uppercase text-white" href="/">Home</a></li>
                    <li class="breadcrumb-item"><a class="text-uppercase text-white" href="#">Blog Pages</a></li>
                    <li class="breadcrumb-item"><a class="text-uppercase text-white" href="#">Blog Grid</a></li>
                    <li class="breadcrumb-item text-white active">Grid 3 Column</li>
                </ol> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlogBreadrumb",
  props:{
    titulo: String,
    descripcion: String,
    background: String
  },
  computed: {
    dinamicBackStyle(){
      return {
        background: this.background
      }
    }
  }
};
</script>

<style>
.blog .breadcrumb-area {
  /*background: rgba(0, 0, 0, 0) url('../../../public/assets/img/Law-photo-min.jpg') ;*/
  background-position: bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
</style>